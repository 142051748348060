import { HotelTeaserFragmentFragment } from '../../../generated/types';
import React from 'react';
import { TeaserProps } from '../../../types/global-types';
import { Box } from '@chakra-ui/react';
import EbikeAtlasTeaser from '../../Common/EbikeAtlasTeaser';

type EbikeAtlasHotelTeaserProps = TeaserProps & {
  data: HotelTeaserFragmentFragment;
};

const EbikeAtlasHotelTeaser: React.FC<EbikeAtlasHotelTeaserProps> = ({
  data,
  showCategory,
  ...rest
}) => {
  return (
    <Box key={data.id} width="100%">
      <EbikeAtlasTeaser
        text={data.detailsForFrontend ? data.detailsForFrontend.body! : ''}
        image={data.teaserImage?.teaserLarge?.url}
        imageAlt={data.teaserImage?.imageAlt}
        imageTitle={data.teaserImage?.imageTitle}
        categoryKey={data.category.key}
        title={data.title}
        region={data.region?.name}
        country={data.country?.name}
        url={data.url}
        type="hotel"
        {...rest}
      />
    </Box>
  );
};

export default EbikeAtlasHotelTeaser;
